import React from "react";

import { SpinnerOverlay,SpinnerContainer } from "./spinner.styles";

const Spinner = () =>(
    <SpinnerOverlay>
        <SpinnerContainer/>
    </SpinnerOverlay>
);
export default Spinner;
